/** @format */

@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@200;300;400;500;600;700;800;900&family=Roboto:ital,wght@0,100;0,400;0,500;0,700;0,900;1,500&family=Ubuntu:ital,wght@0,300;0,400;0,500;0,700;1,400;1,500;1,700&display=swap");

* {
  -webkit-transition: all 0.2s ease;
  -moz-transition: all 0.2s ease;
  -ms-transition: all 0.2s ease;
  -o-transition: all 0.2s ease;
  box-sizing: border-box;
  font-family: "Space Grotesk", sans-serif !important;
}

a {
  text-decoration: none !important;
}

body {
  width: 100% !important;
  scroll-behavior: smooth;
  font-style: normal;

  background:url("./Background balls animations.png"), #01061d ;
}
.section-padding {
  padding: 100px;
}
body::-webkit-scrollbar-track {
  background: #000 !important;
}
body::-webkit-scrollbar-thumb { 
  background: #9d64d6 !important;
  border-radius: 16px;
}
body::-webkit-scrollbar {
  width: 5px;
}
.img-over {
  overflow-x: hidden !important;
}
/****************************************Responsive Navbar **************************************/

.navbar-brand img {
  filter: brightness(100%);
  -webkit-transition: all 0.3s ease-out !important;
  transition: all 0.3s ease-out !important;
}
.navbar .navbar-toggler {
  border: none !important;
  outline: none !important;
  box-shadow: none !important;
  padding-right: 20px;
}
// .nav-item > .active {
//   color: #000000 !important;
// }
.navbar .navbar-toggler:active,
.navbar-toggler:focus {
  outline: none !important;
}
.navbar .navbar-toggler-icon {
  width: 24px;
  height: 17px;
  background-image: none !important;
  position: relative;
  border-bottom: 2px solid #fff;
  transition: all 300ms linear;
}
.navbar .navbar-toggler-icon:after,
.navbar-toggler-icon:before {
  width: 24px;
  position: absolute;
  height: 2px;
  background-color: #fff;
  top: 0;
  left: 0;
  content: "";
  z-index: 2;
  transition: all 300ms linear;
}
.navbar .navbar-toggler-icon:after {
  top: 8px;
}

.navbar .navbar-toggler[aria-expanded="true"] .navbar-toggler-icon:after {
  transform: rotate(45deg) !important;
}
.navbar .navbar-toggler[aria-expanded="true"] .navbar-toggler-icon:before {
  transform: translateY(8px) rotate(-45deg) !important;
}
.navbar .navbar-toggler[aria-expanded="true"] .navbar-toggler-icon {
  border-color: transparent !important;
}

//***********************************************Hero-section***************************************//
.hero {
  display: flex;
  align-items: center;
  h1 {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 700;
    font-size: 40px;
    line-height: 130%;
    letter-spacing: -0.02em;
    color: #dadee3;
  }
  p {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 170%;
    color: #a5abb3;
  }
  a {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 15px;
    text-align: center;
    letter-spacing: 0.06em;
    text-transform: uppercase;
    margin-left: 15px;
    background: linear-gradient(91.35deg, #fc00ff -108.28%, #00dbde 186.85%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
  }
  .hero-btn {
    margin-left: 15px;
    width: 70px;
    height: 40px;
    border: none;
    background: linear-gradient(103.59deg, #fc00ff -59.66%, #00dbde 136.87%);
    border-radius: 100px;
  }
}
.page-heading {
  display: flex;
  align-items: baseline;
  text-align: center;
  hr {
    color: #fc00ff;
    margin-right: 20px;
  }
  .page-heading-para {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 15px;
    text-align: center;
    letter-spacing: 0.06em;
    text-transform: uppercase;
    background: linear-gradient(91.35deg, #fc00ff -108.28%, #00dbde 186.85%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
  }
}
.aboutcompany {
  h1 {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 700;
    font-size: 40px;
    line-height: 130%;
    letter-spacing: -0.02em;
    color: #dadee3;
  }
  p {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 170%;
    color: #a5abb3;
  }
}

///////////////////////////////////////////////////////////////////////////////
.heading-text {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 40px;
  line-height: 130%;
  letter-spacing: -0.02em;
  color: #dadee3;
}

.sec3-para {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 170%;
  color: #a5abb3;
}
.boxes-para {

  // width: 110px;
  height: 110px;


  background: rgba(44, 58, 75, 0.25);
  backdrop-filter: blur(15px);
  /* Note: backdrop-filter has minimal browser support */

  border-radius: 3px;
  p {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 150%;
    margin-left: 20px;
    color: #a5abb3;
  }
  img{
  filter: brightness(0) invert(1);
  }
}


.sec3-btn {
  display: flex;
  justify-content: center;
  margin-top: 100px;
  button {
    width: 227px;
    height: 59px;
    background: linear-gradient(95.43deg, #fc00ff -49.2%, #00dbde 119.11%);
    border-radius: 100px;
  }
}

.history-heading {
  display: flex;
  align-items: baseline;
  hr {
    border: 1px solid;
    background: linear-gradient(90.19deg, #fc00ff -77.43%, #00dbde 152.66%);
    width: 120px;
    margin-right: 20px;
  }
  p {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 15px;
    text-align: center;
    letter-spacing: 0.06em;
    text-transform: uppercase;
    background: linear-gradient(91.35deg, #fc00ff -108.28%, #00dbde 186.85%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
  }
}
.history-body {
  display: flex;
  flex-direction: column;
  justify-content: center;
  h1 {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 700;
    font-size: 40px;
    line-height: 130%;
    letter-spacing: -0.02em;
    color: #dadee3;
  }
  button {
    width: 299px;
    height: 60px;
    background: #010a22;
    border-radius: 100px;
    color: white;
    border: 1px solid;
    border-color: linear-gradient(95.27deg, #fc00ff -79.64%, #00dbde 189.21%);
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 130%;
    color: #dadee3;
  }
  p {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 170%;
    color: #a5abb3;
  }
}

.achievement-heading {
  display: flex;
  align-items: baseline;
  hr {
    border: 1px solid;
    background: linear-gradient(90.19deg, #fc00ff -77.43%, #00dbde 152.66%);
    width: 120px;
    margin-right: 20px;
  }
  p {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 15px;
    text-align: center;
    letter-spacing: 0.06em;
    text-transform: uppercase;
    background: linear-gradient(91.35deg, #fc00ff -108.28%, #00dbde 186.85%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
  }
}
.achievement-body {
  h1 {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 700;
    font-size: 40px;
    line-height: 130%;
    letter-spacing: -0.02em;
    color: #dadee3;
  }
  p {
    padding: 30px 0px;
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 170%;
    color: #a5abb3;
  }
}
.card {
  margin-top: 50px;
  background: rgba(44, 58, 75, 0.25);
  backdrop-filter: blur(15px);
}
.achievement-card-body {
  h1 {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 700;
    font-size: 40px;
    line-height: 150%;
    text-align: center;

    background: linear-gradient(100.27deg, #fc00ff -73.92%, #00dbde 128.78%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
  }
  p {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 130%;
    color: #dadee3;
  }
}

/////////////////////////////////////////////////////////////////////////////////////
.core-team-heading {
  display: flex;
  align-items: baseline;
  hr {
    border: 1px solid;
    background: linear-gradient(90.19deg, #fc00ff -77.43%, #00dbde 152.66%);
    width: 120px;
    margin-right: 20px;
  }
  p {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 15px;
    text-align: center;
    letter-spacing: 0.06em;
    text-transform: uppercase;
    background: linear-gradient(91.35deg, #fc00ff -108.28%, #00dbde 186.85%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
  }
}
.team-heading {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 40px;
  line-height: 60px;
  letter-spacing: 0.06em;
  color: #dadee3;
}
.team-body {
  p {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 130%;
    color: #ffffff;
  }
  span {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    letter-spacing: 0.005em;
    color: #545d69;
  }
}
.sec {
  width: 100%;
  height: 300px;
  background: #09101d;
}
.review-body {
  margin-top: 80px;
  width: 900px;
  background: rgba(44, 58, 75, 0.25);
  backdrop-filter: blur(15px);
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  p {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 170%;
    text-align: center;
    color: #ebeef2;
  }
  span {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    text-align: center;
    letter-spacing: 0.06em;
    color: #a5abb3;
  }
  h5 {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 170%;
    text-align: center;
    letter-spacing: 0.005em;
    color: #dadee3;
  }
}
.rating {
  margin-top: 10px;
  margin-bottom: 10px;
  width: 75px;
  padding: 7px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #d6acff;
  border-radius: 30px;
}

.banner {
  h1 {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 700;
    font-size: 50px;
    line-height: 130%;
    letter-spacing: -0.01em;
    color: #dadee3;
    display: flex;
    justify-content: center;
    padding: 80px 0px;
  }
}

.join-team {
  display: flex;
  flex-direction: column;
  justify-content: center;
  h1 {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 700;
    font-size: 45px;
    line-height: 130%;
    letter-spacing: -0.02em;
    color: #dadee3;
  }
  p {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 170%;
    color: #a5abb3;
  }
  button {
    width: 272px;
    height: 59px;
    background: linear-gradient(95.43deg, #fc00ff -49.2%, #00dbde 119.11%);
    border-radius: 100px;
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 170%;
    text-align: center;
    letter-spacing: 0.005em;
    color: #ffffff;
  }
}
.trending {
  h1 {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 700;
    font-size: 50px;
    line-height: 130%;
    letter-spacing: -0.02em;
    color: #dadee3;
  }
  p {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 170%;
    color: #a5abb3;
  }
}

.trending-input-btn {
  border: none;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 170%;
  text-align: center;
  letter-spacing: 0.005em;
  color: #ffffff;
  width: 169px;
  margin-left: 20px;
  // height: 59px;
  background: linear-gradient(95.43deg, #fc00ff -49.2%, #00dbde 119.11%);
  border-radius: 100px;
}
.trending-input {
  background: #010a22;
  border: 1px solid #2c3a4b;
  box-sizing: border-box;
  border-radius: 100px;
}
//****************************************Solution-Page********************************************//

@media only screen and (max-width: 600px) {
}

.solution-hero-text {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 45px;
  line-height: 130%;
  letter-spacing: -0.02em;
  color: #dadee3;
}
.card-body {
  h5 {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 34px;
    color: #dadee3;
  }
  p {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 170%;
    letter-spacing: 0.005em;
    color: #a5abb3;
  }
  .hero-btn {
    border: none;
    width: 60px;
    height: 36px;
    background: linear-gradient(103.59deg, #fc00ff -59.66%, #00dbde 136.87%);
    border-radius: 100px;
  }
}

.solution-sec1 {
  h1 {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 700;
    font-size: 45px;
    line-height: 130%;
    letter-spacing: -0.02em;
    color: #dadee3;
  }
  p {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 170%;
    color: #a5abb3;
  }
  button {
    width: 281px;
    border: none;
    height: 59px;
    background: linear-gradient(95.43deg, #fc00ff -49.2%, #00dbde 119.11%);
    border-radius: 100px;
    padding: 16px 60px;
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 170%;
    text-align: center;
    letter-spacing: 0.005em;
    color: #ffffff;
  }
}
.sec1-card {
  height: 220px;
  h1 {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 700;
    font-size: 74px;
    line-height: 150%;
    text-align: center;

    background: linear-gradient(100.27deg, #fc00ff -73.92%, #00dbde 128.78%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
  }
  h3 {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 500;
    font-size: 17px;
    line-height: 170%;
    text-align: center;
    color: #a5abb3;
  }
}
.solution-sec1-para {
  p {
    margin-top: 50px;
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 170%;
    color: #a5abb3;
  }
}

.anvo-heading {
  h1 {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 700;
    font-size: 45px;
    line-height: 130%;
    letter-spacing: -0.02em;
    color: #dadee3;
  }
  p {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 170%;
    color: #a5abb3;
    padding-top: 40px;
    padding-bottom: 40px;
  }
}
.anvo-cards {
  h2 {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 34px;
    color: #dadee3;
  }
  p {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 170%;
    letter-spacing: 0.005em;
    color: #a5abb3;
  }
}
.anvo-card-img {
  background: #fff;
  width: 50px;
  border-radius: 3px;
  padding: 10px;
}

.platform-heading {
  h1 {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 700;
    font-size: 45px;
    line-height: 130%;
    /* or 96px */

    letter-spacing: -0.02em;

    /* Neutral/Neutral-01 */

    color: #dadee3;
  }
  .platform-p {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 170%;
    padding-top: 40px;
    padding-bottom: 40px;
    color: #a5abb3;
  }
}

//??????????????????????????????????????????????? Solution-Page>?????????????>?>????????????????//
.solution-contact {
  background: white;
  margin-top: 50px;
  padding: 12px;
  h1 {
    color: red;
    background: linear-gradient(91.99deg, #fc00ff -46.94%, #00dbde 150.92%);
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 700;
    font-size: 28px;
    line-height: 130%;
    color: #ffffff;
    width: auto;
  }
}
.form-control {
  box-shadow: none !important;
}
.solution-contact-btn {
  background: linear-gradient(95.43deg, #fc00ff -49.2%, #00dbde 119.11%);
  border-radius: 3px;
  width: 181px;
  height: 51px;
  border: none;
  margin-top: 10px;
  // float: right;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 170%;
  /* or 27px */

  text-align: center;
  letter-spacing: 0.005em;

  /* Neutral/White */

  color: #ffffff;
}

.project-heading {
  h1 {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 700;
    font-size: 45px;
    line-height: 130%;
    /* or 83px */

    letter-spacing: -0.02em;

    /* Neutral/Neutral-01 */

    color: #dadee3;
  }
  p {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 170%;

    color: #a5abb3;
  }
}
.sliders {
  overflow-x: hidden;
}
.project-card {
  height: 220px;
  .project-card-body {
    h1 {
      font-family: "Montserrat";
      font-style: normal;
      font-weight: 700;
      font-size: 74px;
      line-height: 150%;
      text-align: center;
      background: linear-gradient(100.27deg, #fc00ff -73.92%, #00dbde 128.78%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;
      text-fill-color: transparent;
    }
    h3 {
      font-family: "Montserrat";
      font-style: normal;
      font-weight: 600;
      font-size: 18px;
      line-height: 170%;
      text-align: center;
      color: #a5abb3;
    }
  }
}
.platform-img {
  width: 285px !important;
  height: 240px !important;
  background: #ffffff !important;
  display: block;
}
.project-details {
  h1 {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 700;
    font-size: 45px;
    line-height: 130%;
    letter-spacing: -0.02em;
    color: #dadee3;
  }
  p {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 170%;
    letter-spacing: 0.005em;
    color: #a5abb3;
  }
  img {
    fill: white;
    color: white;
  }
}

//////////////////////////////////////////////////////////////////////////////
.review-sec-head {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 45px;
  line-height: 130%;
  /* or 83px */

  letter-spacing: -0.02em;

  /* Neutral/Neutral-01 */

  color: #dadee3;
}
.review-sec-body {
  h1 {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 170%;
    color: #dadee3;
  }
  span {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 170%;
    /* identical to box height, or 24px */

    letter-spacing: 0.008em;

    /* Neutral/Neutral-05 */

    color: #858c94;
  }
  p {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 170%;
    /* or 27px */
    color: #a5abb3;
    letter-spacing: 0.005em;
  }
}
.review-rating {
  margin-top: 10px;
  margin-bottom: 10px;
  width: 75px;
  padding: 3px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #784aa6;
  color: white;
  border-radius: 30px;
}

//////////////////////////////////////////////////////////////////////////////////
.contactpage-head {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 45px;
  line-height: 130%;
  /* or 83px */

  letter-spacing: -0.02em;

  /* Neutral/Neutral-01 */

  color: #dadee3;
}
.contactpage-left-col {
  p {
    padding-top: 40px;
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 170%;
    /* or 24px */

    letter-spacing: 0.008em;

    /* Neutral/Neutral-02 */

    color: #dadee3;
  }
  button {
    margin-top: 50px;
    border: none;
    background: linear-gradient(95.43deg, #fc00ff -49.2%, #00dbde 119.11%);
    border-radius: 100px;
    width: 281px;
    height: 59px;
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 170%;
    text-align: center;
    letter-spacing: 0.005em;

    color: #ffffff;
  }
}
.contact-details {
  h2 {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 170%;
    /* identical to box height, or 31px */

    /* Neutral/Neutral-01 */

    color: #dadee3;
  }
  span {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 170%;
    letter-spacing: 0.008em;
    color: #858c94;
  }
}
.contact-heading {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 35px;
  line-height: 170%;
  /* identical to box height, or 31px */

  /* Neutral/Neutral-01 */

  color: #dadee3;
}
.contact-paragraph {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 170%;
  /* identical to box height, or 31px */

  /* Neutral/Neutral-01 */

  color: #dadee3;
}
.contact-form-style {
  background: #fff;
  padding: 12px;
  position: relative;
  padding-top: 0;
  // max-width: 80%;
  border-radius: 10px;
  h1 {
    background: linear-gradient(91.99deg, #fc00ff -46.94%, #00dbde 150.92%);
    border-radius: 10px;
    color: white;
  }
}
.ofc-details {
  position: relative;
  padding-top: 50px;
}

////////////////////////////////////////////////////////////////////////////////
.proposal-section {
  h1 {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 700;
    font-size: 36px;
    line-height: 130%;
    color: #dadee3;
  }
  p {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 170%;
    letter-spacing: 0.008em;
    color: #a5abb3;
  }
}
.proposal-card {
  width: 300px;
  h1 {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 170%;
    /* identical to box height, or 31px */

    text-align: center;

    /* Neutral/Neutral-01 */

    color: #2c3a4b;
  }
  p {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 170%;
    /* identical to box height, or 24px */

    text-align: center;
    letter-spacing: 0.008em;

    /* Neutral/Neutral-05 */

    color: #858c94;
  }
  span {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 170%;
    /* identical to box height, or 24px */

    text-align: center;
    letter-spacing: 0.008em;

    /* Info */

    color: #5b8def;
  }
  button {
    margin-top: 40px;
    background: linear-gradient(93.57deg, #fc00ff -40.47%, #00dbde 135.25%);
    border-radius: 100px;
    width: 217px;
    height: 44px;
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 600;
    font-size: 13px;
    line-height: 170%;
    /* identical to box height, or 24px */

    text-align: center;
    letter-spacing: 0.008em;
    text-transform: uppercase;
    border: none;
    /* Neutral/White */

    color: #ffffff;
  }
}
.office {
  margin-top: 20px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 45px;
  line-height: 130%;
  color: #dadee3;
}
.location-icon {
  width: 50px;
  height: 50px;
  padding: 15px;
  color: red;
  border-radius: 6px;
  background-color: white;
}
.office-country {
  margin-top: 30px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 800;
  font-size: 28px;
  line-height: 130%;
  color: #ffffff !important;
}
.office-location {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 170%;
  color: #fefeff;
}
.list-unstyled li {
  display: inline;
  background: #ffffff;
  width: 30px;
  padding: 10px;
  border-radius: 50%;
  height: 30px;
  margin: 10px;
}
.list-unstyled a {
  color: #000;
  font-size: 20px;
}
.phone {
  text-decoration: none;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 170%;

  color: #ffffff;
}
hr {
  background: #fff;
  height: 3px !important;
  position: relative;
}
.blog-row {
  h1 {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 700;
    font-size: 45px;
    line-height: 130%;
    /* or 83px */
    letter-spacing: -0.02em;

    /* Neutral/Neutral-01 */

    color: #dadee3;
  }
}
.overlay-text {
  background: #784aa6 !important;
  opacity: 0.25 !important;
  backdrop-filter: blur(50px);
  display: flex;
  justify-content: space-between;
  width: 100% !important;
  p {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 170%;
    letter-spacing: 0.008em;
    text-transform: uppercase;
    color: #dadee3;
  }
}
.blog-card-body {
  h1 {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 34px;
    text-align: start;
    color: #dadee3 !important;
  }
  p {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 170%;
    /* or 27px */

    letter-spacing: 0.005em;

    /* Neutral/Neutral-02 */

    color: #a5abb3;
  }
  a {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 170%;
    text-decoration: none;
    color: #5b8def;
    cursor: pointer;
  }
}
.blog-detail-heading {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 45px;
  line-height: 130%;
  /* or 73px */

  color: #dadee3;
}
.blog-banner {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: rgb(251, 251, 251);
  text-align: justify;
  padding: 10px 5px;
  p {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 170%;
    /* or 24px */
    letter-spacing: 0.008em;
    text-transform: uppercase;

    /* Test */

    color: #784aa6;
  }
}
.blog-details-para {
  padding-top: 20px;
  padding-bottom: 20px;
  p {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 170%;
    /* or 27px */
    color: #a5abb3;
    letter-spacing: 0.005em;
  }
}
.blog-details-bottom {
  h1 {
    margin-top: 20px;
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 700;
    font-size: 45px;
    line-height: 130%;
    /* or 73px */
    color: #dadee3;
  }
  p {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 170%;
    /* or 27px */
    color: #a5abb3;
    letter-spacing: 0.005em;
  }
  h3 {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 170%;
    /* or 27px */
    color: #dadee3;
    letter-spacing: 0.005em;
  }
}
.ft-bg {
  position: absolute;
  object-fit: cover;
  left: 0;
}
.contact-bg {
  background: linear-gradient(
      95.8deg,
      rgba(173, 0, 255, 0.75) -9.01%,
      rgba(0, 89, 222, 0.1125) 141.98%
    ),
    url(./ft-bg.png) no-repeat;
  background-size: 100% 100%;
  // width: 100%;
  // height: auto;
}
.wrapper {
  // position: absolute;
  // top: 50%;
  // left: 50%;
  // transform: translate(-50%, -50%);
  margin-top: 80px;
  width: 100%;
  max-width: 550px;
  background: rgba(0, 0, 0, 0.8);
  padding: 30px;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
}

.wrapper .title h1 {
  color: #c5ecfd;
  text-align: center;
  margin-bottom: 25px;
}

.contact-form {
  display: flex;
}

.input-fields {
  display: flex;
  flex-direction: column;
  margin-right: 4%;
}

.input-fields,
.msg {
  width: 48%;
}

.input-fields .input,
.msg textarea {
  margin: 10px 0;
  background: transparent;
  border: 0px;
  border-bottom: 2px solid #c5ecfd;
  padding: 10px;
  color: #c5ecfd;
  width: 100%;
  outline: none !important;
  box-shadow: none;
}

.msg textarea {
  height: 212px;
}

::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: #c5ecfd;
}
::-moz-placeholder {
  /* Firefox 19+ */
  color: #c5ecfd;
}
:-ms-input-placeholder {
  /* IE 10+ */
  color: #c5ecfd;
}

.btn-s {
  background: linear-gradient(93.57deg, #fc00ff -40.47%, #00dbde 135.25%);

  text-align: center;
  padding: 15px;
  border-radius: 5px;
  color: #fff;
  cursor: pointer;
  text-transform: uppercase;
}

@media screen and (max-width: 600px) {
  .contact-form {
    flex-direction: column;
  }
  .msg textarea {
    height: 80px;
  }
  .input-fields,
  .msg {
    width: 100%;
  }
}
@media only screen and (max-width: 600px) {
  .solution-hero-text {
    font-size: 30px !important;
  }
  .anvo-heading {
    h1 {
      font-size: 30px !important;
    }
  }
  .line-break {
    display: none;
  }
  .solution-sec1 {
    h1 {
      font-size: 30px !important;
    }
    button {
      margin-left: 20px;
      font-size: 14px !important;
      padding: 0px !important;
    }
  }
  .sec1-card {
    h3 {
      font-size: 14px !important;
    }
  }
  .platform-heading {
    h1 {
      font-size: 30px !important;
    }
  }
  // .platform-img {
  //   width: 285px !important;
  //   height: 240px !important;
  //   background: #ffffff !important;
  //   display: block;
  //   // img {
  //   //   margin: 10px 0px;
  //   //   width: 200px !important;
  //   // }
  // }
  .contact-bg {
    display: none;
  }
  .solution-contact {
    width: 100% !important;
  }
  .project-heading {
    h1 {
      font-size: 30px;
    }
  }
  .project-card {
    height: 190px !important;
  }
  .project-card-body {
    h1 {
      font-size: 50px !important;
    }
    h3 {
      font-size: 16px !important;
    }
  }
  .project-details {
    h1 {
      font-size: 30px !important;
    }
  }
  .review-sec-head {
    font-size: 30px;
  }
  .review-sec-body {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .review-img {
    text-align: center;
    display: flex;
    justify-items: center;
    align-items: center;
  }
  .contactpage-head {
    font-size: 30px;
  }
  #res-textarea {
    width: 100% !important;
  }
  .section-padding {
    padding: 30px 15px !important;
    // text-align: justify;
  }
  .hero {
    h1 {
      font-size: 30px !important;
    }
  }
  .hero-res-img {
    text-align: start !important;
    margin-top: 20px;
    img {
      width: 100%;
    }
  }
  .review-body {
    width: 100% !important;
  }
  .history-body {
    button {
      width: 150px !important;
      height: 50px !important;
    }
  }
  .team-body {
    img {
      width: 90%;
    }
    p {
      font-size: 18px !important;
    }
    span {
      font-size: 14px;
    }
  }
  .banner {
    h1 {
      display: block;
      font-size: 20px !important;
      padding: 0px !important;
    }
  }
  .sec {
    width: 100% !important;
    height: 150px !important;
    background: #09101d;
    display: flex;
    align-items: center;
    img {
      width: 80% !important;
    }
  }
  .join-team {
    h1 {
      font-size: 30px !important;
    }
    p {
      font-size: 16px !important;
    }
    button {
      width: 200px !important;
      font-size: 14px !important;
    }
  }
  .res-contact-details {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .res-contact-img {
    text-align: center;
  }
  .contact-details {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }
  .contactpage-left-col {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    button {
      padding-top: 0px !important;
      padding: 10px !important;
      width: 220px;
      margin-bottom: 20px;
    }
  }
  #form-margin {
    margin-top: 20px;
  }
}

.nav-link {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 170%;
  /* or 27px */

  text-align: center;
  letter-spacing: 0.005em;

  /* Neutral/Neutral-07 */

  color: #dadee3 !important  ;
  &:hover {
    color: #9d64d6 !important;
  }
}
.nav-item.active {
  color: #9d64d6 !important;
}

.contactus-btn {
  background: linear-gradient(95.43deg, #fc00ff -49.2%, #00dbde 119.11%);
  border-radius: 100px;
  border: none;
  width: 150px;
  height: 50px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 170%;
  /* Info */
  text-decoration: none;
  color: #dadee3;
}
